import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";
import { uk } from "date-fns/locale";
import { type GroupByDate } from "~/utils/analytics";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function dateToReadable(
  date: string | number | Date | undefined | null,
): string {
  if (!date) {
    return "";
  }
  return format(date, "LLL dd, y", { locale: uk });
}

export function weekToReadable(
  date: string | number | Date | undefined | null,
): string {
  if (!date) {
    return "";
  }
  return format(date, "dd, LLL", { locale: uk });
}

export function dayToReadable(
  date: string | number | Date | undefined | null,
): string {
  if (!date) {
    return "";
  }
  return format(date, "iii", { locale: uk });
}

export function monthToReadable(
  date: string | number | Date | undefined | null,
): string {
  if (!date) {
    return "";
  }
  return format(date, "LLL", { locale: uk });
}

export function groupByDateToReadableLineChartTooltip(
  groupBy: GroupByDate,
  date: string | number | Date | undefined | null,
) {
  try {
    if (groupBy === "month") {
      return monthToReadable(date);
    }
    return dateToReadable(date);
  } catch (e) {
    console.error(e);
    return "error";
  }
}

export function groupByDateToReadable(
  groupBy: GroupByDate,
  date: string | number | Date | undefined | null,
) {
  try {
    if (groupBy === "day") {
      return dayToReadable(date);
    }
    if (groupBy === "month") {
      return monthToReadable(date);
    }
    return weekToReadable(date);
  } catch (e) {
    console.error(e);
    return "error";
  }
}

export const isDefined = <T>(value: T | undefined | null): value is T => {
  return value !== undefined && value !== null;
};

export function iteratorToStream(iterator: AsyncGenerator<string, void>) {
  return new ReadableStream<string>({
    async pull(controller) {
      const { value, done } = await iterator.next();

      if (done) {
        controller.close();
      } else {
        controller.enqueue(value);
      }
    },
  });
}
