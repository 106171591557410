import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppType } from "next/app";
import { appWithTranslation } from "next-i18next";
import { Source_Serif_4 } from "next/font/google";
import { api } from "~/utils/api";
import { ThemeProvider } from "~/components/theme-provider";
import "~/styles/globals.css";
import Head from "next/head";

import nextI18NextConfig from "next-i18next.config.cjs";

const source_serif_4 = Source_Serif_4({
  subsets: ["latin"],
  weight: ["600"],
});

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  return (
    <>
      <Head>
        <link
          href="https://fonts.cdnfonts.com/css/sf-pro-display"
          rel="stylesheet"
        />
      </Head>

      <ThemeProvider defaultTheme="light" attribute={"class"}>
        <style jsx global>{`
          html {
            --sans-font: "SF Pro Display", sans-serif;
            --serif_4: ${source_serif_4.style.fontFamily};
          }
        `}</style>
        <Head>
          <title>HealtyMind manager</title>
          <link rel="icon" href="/favicon.svg" />
        </Head>
        <SessionProvider session={session}>
          <Component {...pageProps} />
        </SessionProvider>
      </ThemeProvider>
    </>
  );
};

export default api.withTRPC(appWithTranslation(MyApp, nextI18NextConfig));
